<template>
  <div class="is-fullwidth">
    <div v-if="salesForceRecords && salesForceRecords.length > 0">
      <table class="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th colspan="4" style="text-align: right; font-weight: 400!important;">
              <strong>{{ getAverageDuration() }}</strong> avg duration<br><span><small>last 30 days</small></span>
            </th>
          </tr>
          <tr>
            <th style="width: 35%;">Import Job</th>
            <th style="width: 25%;">Status</th>
            <th style="width: 30%;">Start Time</th>
            <th style="width: 10%;">Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in sort(salesForceRecords, 'desc', 'runId')" :key="record.runId" :class="{ 'fade': isUnjamming && record.status === 'Loading' }">
            <td class="is-monospaced">{{ record.process_name }} #{{formatRunId(record.runId)}}</td>
            <td class="is-monospaced">
              <v-popover v-if="record.hadError">
                {{record.status}} {{record.hadError ? '(with error)' : ''}}
                <template slot="popover" id="popover">
                  {{record.error}}
                </template>
              </v-popover>
              <span v-else :class="{
                'has-text-danger': record.status === 'Failed',
                'text-warning': record.status === 'Loading',
                'text-grey': record.status === 'Starting Load',
                'text-info': record.status === 'Load Complete',
                'semi-bold': record.status === 'Loading',
                'font-italic': record.status === 'Loading',
                'magnify': record.status === 'Loading',
              }">{{record.status}}</span>
            </td>
            <td>
              <span class="is-monospaced">{{moment(record.runDate)}}</span>
              <span v-if="getDurationRaw(record.runDate, record.updatedDate) > 15 && (record.status === 'Loading' || record.status === 'Pending Load')">
                <a href="javascript:void()" style="margin-left: 5px;" alt="Try to unjam the process" @click="unjamProcess">
                  <i v-if="!isUnjamming" :class="['fas fa-redo']" alt="refresh-icon"></i>
                  <i v-else class="fas fa-spinner fa-spin fa-pulse"></i>
                </a>
              </span>
            </td>
            <td>
              <span class="is-monospaced">{{getDuration(record.runDate, record.updatedDate)}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <LoadingVue v-else />
  </div>
</template>

<script>
import LoadingVue from '../LoadingInline.vue'
import moment from 'moment-timezone'
import _ from 'lodash'

class SalesforceRun {
  mappedValues = {
    0: 'runId',
    1: 'status',
    2: 'isLocked',
    3: 'attempts',
    4: 'runDate',
    5: 'updatedDate',
    6: 'is_quarterly',
    7: 'process_name'
  }

  constructor () {
    this.runId = null
    this.status = null
    this.isLocked = null
    this.attempts = null
    this.runDate = null
    this.updatedDate = null
    this.is_quarterly = null
    this.process_name = null
  }

  static fromJson (json) {
    let obj = new SalesforceRun()
    for (let key in json) {
      if (obj.mappedValues[key]) {
        obj[obj.mappedValues[key]] = json[key]
      }
    }
    return obj
  }
}

export default {
  name: 'SalesForceRuns',
  components: {
    LoadingVue
  },
  emits: ['hasRunningJob'],
  props: {
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      salesForceRecords: [],
      isUnjamming: false,
      interval: null
    }
  },
  watch: {
    refresh: function (val) {
      if (val) {
        this.salesForceRecords = []
        this.getRecords()
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.interval = null
  },
  mounted () {
    this.getRecords()

    // this.interval = setInterval(() => {
    //   this.getRecords()
    // }, 5000)
  },
  methods: {
    getAverageDuration  () {
      let total = 0

      const records = this.salesForceRecords.filter((record) => {
        if ((moment(record.runDate, 'MM/DD/YYYY').diff(moment(), 'days') * -1) < 30) {
          return record
        }
      })

      records.forEach(record => {
        total += this.getDurationRaw(record.runDate, record.updatedDate)
      })

      return Math.round(total / records.length) + ' min'
    },
    formatRunId (runId) {
      // Formats as 6 digits
      return runId.toString().padStart(4, '0')
    },
    moment (date) {
      return this.$formatDateInLocalTimezone(date, 'MM/DD/YYYY hh:mm A') // moment(date).format('MM/DD/YYYY hh:mm A')
    },
    getRunDiff (date) {
      return moment(this.$formatDateInLocalTimezone(date, 'MM/DD/YYYY hh:mm A')).diff(moment(), 'minutes') * -1
    },
    getDuration (start, end) {
      // Convert start and end times to moment objects directly
      let startTime = moment(start)
      let endTime = moment(end)

      // If the formatted start and end times are the same, set endTime to the current moment
      if (start === end) {
        end = moment().utc().format('MM/DD/YYYY HH:mm:ss')
        endTime = moment(end)
      }

      // Calculate the difference in minutes
      const durationMinutes = endTime.diff(startTime, 'minutes')

      // Return 0 if the duration is negative, otherwise return the duration
      return durationMinutes + ' min'
    },

    getDurationRaw (start, end) {
      let value = this.getDuration(start, end)

      return parseInt(value.replace(' min', ''))
    },
    triggerNewImport (type) {
      this.requestNewImport(type)
    },
    async requestNewImport (type) {
      this.api().etl.newSalesforceImport(type, (result) => {
        if (result && result.data && result.data === true) {
          this.successToast(type === 'both' ? 'New import requested' : `New ${type} import requested`)
        } else if (result && !result.data) {
          this.errorToast(`${type} import request failed: import already in progress`)
        }

        setTimeout(() => {
          this.getRecords()
        }, 2500)
      })
    },
    async determineIfRecordsHasRunningJob () {
      let hasRunningJob = false
      this.salesForceRecords.forEach((record) => {
        if (record.status === 'Starting Load' || record.status === 'Loading') {
          hasRunningJob = true
        }
      })

      this.$emit('hasRunningJob', hasRunningJob)
    },
    async unjamProcess () {
      // note: might be used in some specific cases on a give salesforce row
      this.isUnjamming = true
      this.api().etl.unjamSalesforceImport(async (result) => {
        if (result && result.data && result.data === true) {
          this.successToast('Salesforce import unjammed')
          this.getRecords()
        } else if (result && !result.data) {
          this.errorToast('Salesforce import unjam failed')
          this.getRecords()
        }

        setTimeout(async () => {
          this.salesForceRecords = []
          await this.getRecords()
          this.isUnjamming = false
        }, 500)
        // await this.requestProcessing()
      })
    },
    async requestProcessing () {
      // note: not used, last spot was in the unjamProcess
      this.api().etl.requestSalesforceProcessing((result) => {
        if (result && result.data && result.data === true) {
          this.successToast('Salesforce processing requested')
          this.getRecords()
        } else if (result && !result.data) {
          this.errorToast('Salesforce processing request failed')
          this.getRecords()
        }
      })
    },
    sort (collection, direction, field) {
      if (typeof collection === 'undefined' || collection.length === 0 || !Array.isArray(collection)) {
        return collection
      }

      return _.sortBy(collection, field, direction).reverse()
    },
    async getRecords () {
      this.api().etl.Salesforce((result) => {
        if (result && result.data && result.data !== '[]') {
          let salesForceRuns = JSON.parse(result.data).map(record => SalesforceRun.fromJson(record))
          this.salesForceRecords = salesForceRuns.map(record => {
            let diff = moment(this.$formatDateInLocalTimezone(record.runDate, 'YYYY-MM-DD HH:mm:ss')).diff(moment(), 'minutes') * -1

            let hasStalled =
              diff > 10 &&
              record.status.toLowerCase().includes('running')

            record.status = hasStalled ? 'stalled' : record.status
            record.hadError = hasStalled ? true : record.attempts > 1
            record.error = hasStalled ? 'Salesforce Import has stalled. Retry.' : 'Something went wrong in the import. Retry.'

            return record
          })
        }

        this.determineIfRecordsHasRunningJob()
      })
    }
  }
}
</script>

<style scoped>
.v-popover .trigger {
  cursor: pointer!important;
}

.popover-inner {
  word-break: break-all;
  width: calc(100% * 2.32);
}

.countdown {
  text-align: center;
  margin-top: -10px;
}

.magnify {
  animation: magnify 1s infinite!important;
  display: flex;
}

@keyframes magnify {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}

.text-warning {
  color: #eaae5e!important;
}

.text-grey {
  color: #7fb942!important;
}

.text-info {
  color: #3d567d!important;
}

.fade {
  animation: magnify 1s infinite!important;
  /* opacity: .5!important; */
}

tr {
  max-height: 35px!important;
  overflow: hidden;
}

tr td {
  max-height: 35px!important;
  vertical-align: middle;
  overflow: hidden;
  word-break: keep-all!important;
}

tr td.name {
  height: 35!important;
  width: calc(100% - 400px)!important;
  word-break: keep-all!important;
  overflow: hidden;
}
</style>
